import { useCallback } from 'react';

import { useClearAppData } from '../common/api/hooks/useClearAppData';
import { signOut } from '../common/auth';

export function useDeleteSession() {
  const clearAppData = useClearAppData();

  return useCallback(async () => {
    await signOut();
    clearAppData();
  }, [clearAppData]);
}
