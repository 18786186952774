import { useCallback } from 'react';

import { clearSiteData } from '../../session';
import { API_RESOURCES } from '../resources';
import { useClearCache } from './useClearCache';

export function useClearAppData() {
  const clearCache = useClearCache();

  return useCallback(() => {
    clearSiteData();
    clearCache(undefined, { isRevalidationDisabled: true, omit: [API_RESOURCES.featureFlag.path] });
    clearCache('featureFlag');
  }, [clearCache]);
}
