import { CURRENCY } from '@sbiz/common';

export async function asyncFilter<TItem>(
  items: TItem[],
  predicate: (item: TItem, index: number, items: TItem[]) => Promise<boolean>,
  options: { isInSequence?: boolean },
) {
  const result = await asyncMap(
    items,
    async (item, index, items) => {
      const isMatch = await predicate(item, index, items);
      return isMatch && item;
    },
    options,
  );
  return result.filter(Boolean) as TItem[];
}

export async function asyncFlatMap<TItem, TResultItem>(
  items: TItem[],
  callback: (item: TItem, index: number, items: TItem[]) => Promise<TResultItem | TResultItem[]>,
  options?: { isInSequence?: boolean },
) {
  const result = await asyncMap(items, callback, options);
  return result.flat() as TResultItem[];
}

export async function asyncMap<TItem, TResultItem>(
  items: TItem[],
  callback: (item: TItem, index: number, items: TItem[]) => Promise<TResultItem>,
  options?: { isInSequence?: boolean },
) {
  if (options?.isInSequence) {
    const result: TResultItem[] = [];

    for (const [index, item] of items.entries()) {
      const resultItem = await callback(item, index, items);
      result.push(resultItem);
    }

    return result;
  }

  const result = await Promise.all(items.map(callback));
  return result;
}

export function doNotAwait(
  asyncExpression: Promise<unknown> | ((...args: unknown[]) => Promise<unknown>),
  onError?: (error: unknown) => void,
) {
  const promise = typeof asyncExpression === 'function' ? asyncExpression() : asyncExpression;
  promise.catch((error) => {
    onError?.(error);
  });
}

export function getContrastedColor(hexColor: string) {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1_000;

  return yiq < 128 ? 'white' : 'black';
}

export function getSortedEntries(object: object, maxDepth = 20, depth = 0) {
  return Object.entries(toJSON(object))
    .map(([key, value]): [string, unknown] => {
      if (Array.isArray(value)) {
        return [key, getSortedItems(value, maxDepth, depth)];
      }

      if (isPlainObject(value) && depth <= 20) {
        return [key, getSortedEntries(value, maxDepth, depth + 1)];
      }

      return [key, value];
    })
    .sort(([a], [b]) => a.localeCompare(b));
}

export function getSortedItems(items: unknown[], maxDepth = 20, depth = 0) {
  return items
    .map((item): unknown => {
      if (Array.isArray(item)) {
        return depth <= 20 ? getSortedItems(item, maxDepth, depth + 1) : item;
      }

      if (isPlainObject(item)) {
        return getSortedEntries(item, maxDepth, depth);
      }

      return item;
    })
    .sort();
}

export function getTotal<T extends { amount: number }>(items: T[]): number;
export function getTotal<T extends { [K in P]: number }, P extends keyof T>(items: T[], key: P): number;
export function getTotal<T extends { [K in P]: number }, P extends keyof T>(items: T[], key?: P) {
  return items.reduce((total, { [key ?? 'amount']: value }) => total + value, 0);
}

export function getUniqueItems<T>(array: T[]) {
  return Array.from(new Set(array));
}

export function isObjectLitteral(value: unknown): value is object {
  return Boolean(value) && Object.getPrototypeOf(value) === Object.prototype;
}

export function isObjectOrFunction(value: unknown): value is object {
  return typeof value === 'function' || isPlainObject(value);
}

export function isPlainObject(value: unknown): value is object {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
}

export function isInArray<T>(value: unknown, array: T[] | Readonly<T[]>): value is T {
  return array.includes(value as T);
}

export function isInSet<T>(value: unknown, set: Set<T>): value is T {
  return set.has(value as T);
}

export function isKeyInObject<T extends object>(key: unknown, object: T): key is keyof T {
  return typeof key === 'string' && key in object;
}

export function isKeyInMap<T>(key: unknown, map: Map<T, unknown>): key is T {
  return map.has(key as T);
}

export function omit<T extends object, K extends (keyof T)[] | Readonly<(keyof T)[]>>(object: T, keys: K) {
  const result = { ...object };

  for (const key of keys) {
    delete result[key];
  }

  return result as Omit<T, K[number]>;
}

export function pick<T extends object, K extends (keyof T)[] | Readonly<(keyof T)[]>>(object: T, keys: K) {
  const result = {} as { [P in K[number]]: T[P] };

  for (const key of keys) {
    if (key in object) {
      result[key] = object[key];
    }
  }

  return result;
}

export function safeJSONParse<T = unknown>(value: unknown) {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value) as T;
    } catch {
      return undefined;
    }
  }
}

export function sleep(durationMs: number) {
  return new Promise((resolve) => setTimeout(resolve, durationMs));
}

export function toCentimes(value: number | string | null | undefined) {
  const amountChf = typeof value === 'string' ? parseFloat(value) : value;

  if (!amountChf) {
    return 0;
  }

  return Math.round(Math.abs(amountChf) * 100);
}

export function toChf(value: number | string | null | undefined) {
  const amountCentimes = typeof value === 'string' ? parseFloat(value) : value;

  if (!amountCentimes) {
    return 0;
  }

  return Math.round(Math.abs(amountCentimes)) / 100;
}

export function toJSON(object: object) {
  return JSON.parse(JSON.stringify(object));
}

export function toMoney(value: string | number | null | undefined, unit: 'chf' | 'centimes' = 'centimes') {
  const amount = typeof value === 'number' ? value : parseFloat(value ?? '0');
  const amountChf = unit === 'chf' ? amount : amount / 100;
  return Intl.NumberFormat('de-CH', { style: 'currency', currency: CURRENCY }).format(amountChf);
}
