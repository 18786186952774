import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

import { API_RESOURCES, ResourceType } from '../resources';
import { ClearCacheOptions, ClearCacheResourceTypeParam } from '../types';

export function useClearCache() {
  const { mutate } = useSWRConfig();

  return useCallback(
    (resourceType?: ClearCacheResourceTypeParam, options?: ClearCacheOptions) => {
      mutate((key) => typeof key === 'string' && isMutated(key, resourceType, options), undefined, {
        revalidate: !options?.isRevalidationDisabled,
      });
    },
    [mutate],
  );
}

function isMatchingKey(key: string, resourceType: ResourceType | undefined, options?: ClearCacheOptions) {
  const isOmitted = options?.omit?.some((exclusion) => new RegExp(exclusion).test(key));

  if (isOmitted) {
    return false;
  }

  if (resourceType) {
    // no-dd-sa:typescript-browser-security/regexp-non-literal,typescript-node-security/detect-non-literal-regexp
    const pathRegex = new RegExp(`${API_RESOURCES[resourceType].path}${options?.path ? `/${options.path}` : ''}`);
    return pathRegex.test(key);
  }

  return true;
}

function isMutated(key: string, resourceType: ClearCacheResourceTypeParam | undefined, options?: ClearCacheOptions) {
  const resourceTypes = Array.isArray(resourceType) ? resourceType : [resourceType];

  return resourceTypes.some((resourceType) => {
    if (isMatchingKey(key, resourceType, options)) {
      return true;
    }

    if (resourceType) {
      const adminResourceType = resourceType && `${resourceType}Admin`;
      return adminResourceType in API_RESOURCES && isMatchingKey(key, adminResourceType as ResourceType, options);
    }

    return false;
  });
}
