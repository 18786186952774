import { KeycloakPaths } from './types';

export function getDeploymentConfig() {
  return {
    appName: process.env.BUILD_INFO_APPNAME || process.env.APP_NAME || '',
    branch: process.env.BUILD_INFO_BRANCH || '',
    commitHash: process.env.BUILD_INFO_COMMIT_HASH || '',
    commitHashShort: process.env.BUILD_INFO_COMMIT_HASH_SHORT || '',
    date: process.env.BUILD_INFO_DATE || '',
    environment: process.env.BUILD_INFO_ENVIRONMENT || '',
    host: process.env.VIRTUAL_HOST || '',
    npmPackageName: process.env.npm_package_name || '',
    npmPackageVersion: process.env.npm_package_version || '',
    path: process.env.INGRESS_PATH || '',
    version: process.env.BUILD_INFO_VERSION || '',
  } as const;
}

export function getKeycloakConfig(options?: { isPublic?: boolean }) {
  const basePath = 'protocol/openid-connect';
  const externalUrl = process.env.GLOBAL_KEYCLOAK_URL_EXT || '';
  const internalUrl = process.env.GLOBAL_KEYCLOAK_URL_INT || '';
  const realm = process.env.GLOBAL_KEYCLOAK_REALM || '';

  return {
    clientId: process.env.KEYCLOAK_CLIENT_ID || '',
    clientSecret: (!options?.isPublic && process.env.KEYCLOAK_CLIENT_SECRET) || '',
    paths: {
      certs: `${basePath}/certs`,
      logout: `${basePath}/logout`,
      token: `${basePath}/token`,
    } satisfies KeycloakPaths,
    urls: { external: `${externalUrl}/realms/${realm}`, internal: `${internalUrl}/realms/${realm}` },
  } as const;
}

export function getMongoDbConfig() {
  const dbName = process.env.MONGO_DBNAME || '';
  const password = process.env.MONGO_PASSWORD || '';
  const uriTemplate = process.env.GLOBAL_DB_SRV_URI_INT || '';
  const username = process.env.MONGO_USERNAME || '';

  return {
    dbName,
    password,
    uri: uriTemplate.replace('USERNAME', username).replace('PASSWORD', password).replace('DBNAME', dbName),
    username,
  } as const;
}
