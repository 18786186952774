// no-dd-sa:no-unnecessary-class

export class Console {
  static debug(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.debug(...args);
  }

  static log(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.log(...args);
  }

  static error(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.error(...args);
  }

  static warn(...args: unknown[]) {
    // no-dd-sa:typescript-best-practices/no-console
    console.warn(...args);
  }
}
